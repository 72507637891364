import React, { useState, useContext, useEffect } from 'react';
import { Route, useLocation, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { UserContext } from './UserContext.js';
import { loginRequest } from "./auth/AuthConfig";
import { Menubar } from 'primereact/menubar';
import AppTopBar from './AppTopbar';
import AppMenu from './AppMenu';
import AppInlineProfile from './AppInlineProfile';
import { TenantMetadata } from './components/TenantMetadata';
import { RecordEntry } from './pages/RecordEntry';
import { Users } from './pages/Users';
import { Locations } from './pages/Locations';
import { Sections } from './pages/Sections';
import { Units } from './pages/Units';
import { TenantDashboard } from './pages/TenantDashboard';
import { ConcessionsGateNow } from './pages/ConcessionsGateNow.js';
import { OperationHours } from './pages/OperationHours';
import { Button } from 'primereact/button';
import { ToolDrop } from './pages/ToolDrop';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import { TabMenu } from 'primereact/tabmenu';
import { LeaseHours } from './pages/LeaseHours.js';
import { GateNowMap } from './pages/GateNowMap.js';
import { Home } from './pages/Home.js';
import AppSettings from "./AppSettings.json";
const App = () => {
	const [menuActive, setMenuActive] = useState(false);
	const [profileExpanded, setProfileExpanded] = useState(false);
	const [menuMode, setMenuMode] = useState('horizontal');
	const [overlayMenuActive, setOverlayMenuActive] = useState(false);
	const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
	const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
	const [topbarMenuActive, setTopbarMenuActive] = useState(false);
	const [activeTopbarItem, setActiveTopbarItem] = useState(null);
	const [configActive, setConfigActive] = useState(false);
	const [inputStyle, setInputStyle] = useState('outlined');
	const [ripple, setRipple] = useState(false);
	const [currentTenant, setCurrentTenant] = useState();
	const [activeIndex, setActiveIndex] = useState(0);
	const location = useLocation();
	const history = useHistory();
	const user = useContext(UserContext);
	//console.log(user)
	const { instance } = useMsal();
	const groupAccess = AppSettings.GROUP_ACCESS?.split(",");
	const isAdmin = user?.userInfo?.data?.groups?.some(group => groupAccess?.includes(group));
	// //console.log("Groups that have access", groupAccess)
	// console.log("Your Groups ", user?.userInfo?.data?.groups)
	// console.log("Access Given ? :", isAdmin)
	// console.log("user Email", user.username)
	let menuClick = false;
	let topbarItemClick = false;
	let configClick = false;

	const menu = [
		{
			label: 'Menu',
			items: [
				{ label: 'Records', icon: 'pi pi-fw pi-lock', to: '/records' },
				{ label: 'Users', icon: 'pi pi-fw pi-circle-off', to: '/users' }
			]
		},
	];

	const adminMenu = [
		{ label: 'Home', icon: 'pi pi-fw pi-home', to: '/' },
		{ label: 'Tenants', icon: 'pi pi-fw pi-users', to: '/tenants' },
		{ label: 'Sections', icon: 'pi pi-fw pi-sitemap', to: '/sections' },
		{ label: 'Locations', icon: 'pi pi-fw pi-map-marker', to: '/locations' },
		{ label: 'Units', icon: 'pi pi-fw pi-box', to: '/units' },
		{ label: 'Tool Drop', icon: 'pi pi-fw pi-cog', to: '/tooldrop' },
		{ label: 'Hours of Operation', icon: 'pi pi-fw pi-clock', to: '/operationHours' },
		{ label: 'Lease Hours', icon: 'fa-solid fa-not-equal', to: '/leasehours' },
		// { label: 'Gates Now', icon: 'fa-solid fa-dungeon', to: '/concessionsgatenow' },
		// { label: 'Map', icon: 'pi pi-fw pi-map', to: '/gatesnowmap' }
	];

	const logMenu = [
		{
			label: 'Menu',
			items: [
				{ label: 'Tool Drop', icon: 'pi pi-fw pi-circle-off', to: '/tooldrop' }
			]
		},
	];

	const routers = [
		{ path: '/', component: RecordEntry, exact: true },
		{ path: '/records', component: RecordEntry },
		{ path: '/users', component: Users },
	];

	const adminRouters = [
		{ path: '/', component: Home, exact: true },
		{ path: '/tenants', component: TenantDashboard, exact: true },
		{ path: '/locations', component: Locations },
		{ path: '/sections', component: Sections },
		{ path: '/units', component: Units },
		{ path: '/tooldrop', component: ToolDrop },
		// { path: '/concessionsgatenow', component: ConcessionsGateNow },
		{ path: '/operationHours', component: OperationHours },
		{ path: '/leasehours', component: LeaseHours },
		// { path: '/gatesnowmap', component: GateNowMap }
	];

	const logRouters = [
		{ path: '/', component: ToolDrop },
		{ path: '/tooldrop', component: ToolDrop }
	];

	const setTenant = (tenantId) => {
		user.setTenant(tenantId);
	};

	const handleGoBackButton = () => {
		// This function is triggered when the back button is pressed
		if (activeIndex !== 0) {
			history.goBack();
		} else {
			// If on the first tab, handle the logic to go back to the previous page
			history.goBack();
		}
	};

	useEffect(() => {
		const storedIndex = localStorage.getItem('activeTabIndex');
		if (storedIndex !== null) {
			setActiveIndex(parseInt(storedIndex));
		} else {
			setActiveIndex(0); // Default value if no stored index found
		}
	}, []);

	useEffect(() => {
		// Update activeIndex based on the current URL
		const path = location.pathname;
		const index = adminMenu.findIndex(item => item.to === path);
		if (index !== -1) {
			setActiveIndex(index);
		}
	}, [location]);

	const onDocumentClick = () => {
		if (!topbarItemClick) {
			setActiveTopbarItem(null);
			setTopbarMenuActive(false);
		}

		if (!menuClick) {
			if (isHorizontal() || isSlim()) {
				setMenuActive(false);
			}

			hideOverlayMenu();
		}

		if (configActive && !configClick) {
			setConfigActive(false);
		}

		menuClick = false;
		topbarItemClick = false;
		configClick = false;
	};

	const onProfileClick = (e) => {
		setProfileExpanded(prevProfileExpanded => !prevProfileExpanded);
	};

	const onMenuClick = () => {
		menuClick = true;
	};

	const onMenuitemClick = (event) => {
		if (!event.item.items) {
			hideOverlayMenu();
		}

		if (!event.item.items && (isSlim() || isHorizontal())) {
			setMenuActive(false);
		}
	};

	const onRootMenuitemClick = () => {
		setMenuActive(false);
	};

	const onMenuButtonClick = (event) => {
		menuClick = true;
		setTopbarMenuActive(false);

		if (menuMode === 'overlay') {
			setOverlayMenuActive(prevOverlayMenuActive => !prevOverlayMenuActive);
		}
		else {
			if (isDesktop())
				setStaticMenuDesktopInactive(prevStaticMenuDesktopInactive => !prevStaticMenuDesktopInactive);
			else
				setStaticMenuMobileActive(prevStaticMenuMobileActive => !prevStaticMenuMobileActive);
		}

		event.preventDefault();
	};

	const onTopbarMenuButtonClick = (event) => {
		topbarItemClick = true;
		setTopbarMenuActive(prevTopbarMenuActive => !prevTopbarMenuActive);
		hideOverlayMenu();
		event.preventDefault();
	};

	const onTopbarItemClick = (e) => {
		topbarItemClick = true;

		if (activeTopbarItem === e.item)
			setActiveTopbarItem(null)
		else
			setActiveTopbarItem(e.item);

		e.originalEvent.preventDefault();
	};

	const hideOverlayMenu = () => {
		setOverlayMenuActive(false);
		setStaticMenuMobileActive(false);
	};

	const isDesktop = () => {
		return window.innerWidth > 1024;
	};

	const isHorizontal = () => {
		return menuMode === 'vertical';
	};

	const isSlim = () => {
		return menuMode === 'slim';
	};

	const isMenuVisible = () => {
		if (isDesktop()) {
			if (menuMode === 'static')
				return !staticMenuDesktopInactive;
			else if (menuMode === 'overlay')
				return overlayMenuActive;
			else
				return true;
		}
		else {
			if (staticMenuMobileActive)
				return true;
			else if (menuMode === 'overlay')
				return overlayMenuActive;
			else
				return true;
		}
	};

	const handleTabChange = (e) => {
		if (e.index === activeIndex) return;
		const route = e.value.to;
		setActiveIndex(e.index);
		localStorage.setItem('activeTabIndex', e.index.toString()); // Store active index in localStorage
		history.push(route);
	};

	let containerClassName = classNames('layout-wrapper', {
		'menu-layout-static': menuMode !== 'overlay',
		'menu-layout-overlay': menuMode === 'overlay',
		'layout-menu-overlay-active': overlayMenuActive,
		'menu-layout-slim': menuMode === 'slim',
		'menu-layout-horizontal': menuMode === 'horizontal',
		'layout-menu-static-inactive': staticMenuDesktopInactive,
		'layout-menu-static-active': staticMenuMobileActive,
		'p-input-filled': inputStyle === 'filled',
		'p-ripple-disabled': !ripple,
	});

	let menuClassName = classNames('layout-menu-container', {
		'layout-menu-dark': false,
		'layout-menu-container-inactive': !isMenuVisible()
	});

	return (
		<div className={containerClassName} onClick={onDocumentClick}>
			<div className="layout-wrapper-content">
				<AppTopBar topbarMenuActive={topbarMenuActive}
					profileMode="top"
					activeTopbarItem={activeTopbarItem}
					onMenuButtonClick={onMenuButtonClick}
					onTopbarMenuButtonClick={onTopbarMenuButtonClick}
					onTopbarItemClick={onTopbarItemClick}>
				</AppTopBar>

				<div>
					<UnauthenticatedTemplate>
						{
							() => {
								instance.loginRedirect(loginRequest).catch(e => {
									console.error(e);
								})
							}
						}
					</UnauthenticatedTemplate>
					<AuthenticatedTemplate>
						{user?.acaaUser !== true && user?.logisticsUser !== true && (user?.tenantInfo === undefined || user?.tenantInfo?.length < 1) ?
							<h1 style={{ marginTop: '10%', textAlign: 'center' }}>
								Your account is not currently associated with a tenant.
								An active user with an existing tenant will need to invite you.
							</h1>
							:
							<>
								{user.acaaUser !== true && (
									<div>
										<div className={menuClassName} onClick={onMenuClick}>
											<div className="menu-scroll-content">
												{menuMode !== 'horizontal' && (
													<AppInlineProfile onProfileClick={onProfileClick} expanded={profileExpanded} />
												)}

												<AppMenu setTenant={setTenant}
													user={user}
													model={user?.acaaUser === true ? adminMenu : user?.logisticsUser === true ? logMenu : menu}
													menuMode={menuMode}
													active={menuActive}
													onMenuitemClick={onMenuitemClick}
													onRootMenuitemClick={onRootMenuitemClick}>
												</AppMenu>
											</div>
										</div>
									</div>
								)}
								{user.acaaUser === true && isAdmin && (
									<div className={menuClassName} onClick={onMenuClick}>
										<div className="menu-scroll-content" >
											<div className='flex' style={{ paddingLeft: "17px" }}>
												{<div ><Button
													icon="pi pi-arrow-left"
													className="p-button-rounded p-button-primary custom-circular-button"
													onClick={() => handleGoBackButton()}
													style={{ marginTop: "10px" }}
												/></div>}
												<div className='col'>
													<TabMenu model={adminMenu} activeIndex={activeIndex} onTabChange={(e) => handleTabChange(e)} >
													</TabMenu>
												</div>
											</div>
										</div>
									</div>
								)}
								<div className="layout-main" >
									{user.acaaUser === true && (isAdmin ?
										(
											<>
												{
													adminRouters.map((router, index) => {
														return (
															<Route key={`router${index}`}
																path={router.path}
																component={router.component ? router.component : router.render}
																exact={router.exact ?? false} />
														)
													})
												}
											</>
										) :
										(
											<div className="layout-main" style={{ textAlign: "center", padding: "20px" }}>
												<h1 style={{ fontSize: "20px", fontWeight: "bold", color: "#333", marginTop: "100px" }}>
													Access Denied
												</h1>
												<p style={{ fontSize: "20px", color: "#555" }}>
													Please request access to the Data & Analytics team to proceed.
												</p>
											</div>
										)
									)}
									{user.logisticsUser === true && (
										<>
											{
												logRouters.map((router, index) => (
													<Route key={`router${index}`}
														path={router.path}
														component={router.component}
														exact={router.exact ?? false} />
												))
											}
										</>
									)}
									{user.logisticsUser !== true && user.acaaUser !== true && (
										<>
											<TenantMetadata tenant={currentTenant} />
											{
												routers.map((router, index) => (
													<Route key={`router${index}`}
														path={router.path}
														component={router.component}
														exact={router.exact ?? false} />
												))
											}
										</>
									)}
								</div>
							</>
						}
					</AuthenticatedTemplate>
					<div className="layout-mask modal-in"></div>
				</div>
			</div>
		</div>
	);
}

export default App;
