import React, { useState, useEffect, useRef, useContext } from 'react';
import { UserContext } from "../UserContext";
import { ConcessionsContext } from "../service/ConcessionsContext";
import { format, addDays } from 'date-fns';
import { dateOnlyTemplate } from '../service/HelpersService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';
import { Config } from './ConfigTab';
import { UsersTab} from './UsersTab';
import { TabMenu } from 'primereact/tabmenu';
import { ConcessionsData } from './ConcessionsDataTab';
import { TabView, TabPanel } from 'primereact/tabview';
import { Users } from './Users';
import { Hours } from './HoursTab';

const formatStr = "yyyy-MM-dd hh:mm:ss";
const exportColumns = [
	{ title: "Date", dataKey: "transactionsDate" },
	{ title: "Monetary", dataKey: "monetaryAmount" },
	{ title: "Transactions", dataKey: "numTransactions" }
];
const addUserMsg = "User added to tenant.";
const addUserFail = "Failed to add user to database.";
const removeUserMsg = "User removed from tenant.";
const tenantRemoveMsg = "Tenant deactivated"
const removeUserFail = "Failed to remove user from tenant.";
const recordUpdateMsg = "Records updated.";
const recordUpdateFail = "Records failed to update.";
const recordAdminFail = "Emails from the @flypittsburgh domain cannot be added as tenants.";

export const Tenants = (props) => {
	const [users, setUsers] = useState([]);
	const [records, setRecords] = useState([]);
	const [leaseStartDate, setLeaseStartDate] = useState([]);
	const [curTenant, setCurTenant] = useState(null)
	const [sumMonetrary, setSumMonetrary] = useState(0);
	const [sumTransaction, setSumTransaction] = useState(0);
	const [displayDialog, setDisplayDialog] = useState(false);
	const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);
	const [displayTenantDeleteDialog, setDisplayTenantDeleteDialog] = useState(false);
	const [userToAdd, setUserToAdd] = useState("");
	const [userToDelete, setUserToDelete] = useState();
	const [userToDeleteId, setUserToDeleteId] = useState();
	const [metadata, setMetadata] = useState();
	const [editState, setEditState] = useState(false);
	const [units, setUnits] = useState();
	const [tenantTypes, setTenantTypes] = useState();
	const [changeObject, setChangeObject] = useState({});
	const [filteredData, setFilteredData] = useState([]);
	const [isManager, setIsManager] = useState(false);
	const [tranDtFlt, setTranDtFlt] = useState([]);
	const [lastModDtFlt, setLastModDtFlt] = useState([]);
	const [modByEmail, setModByEmail] = useState("");

	const [activeIndex, setActiveIndex] = useState(0);
	

	const dt = useRef(null);
	const user = useContext(UserContext);
	const global = useContext(ConcessionsContext);



	
	useEffect(() => {
		setCurTenant(props.tenant);

		if (props.tenant === "new") {
			setEditState(true)
			setChangeObject({})
		}
		
	}, []);

	useEffect(() => {
		user.apiCall(`units`)
			.then(res => {
				setUnits(res.data)
			})

		user.apiCall('tenantTypes')
			.then(res => {
				setTenantTypes(res.data)
			})

		if (curTenant?.tenantId) {
			user.apiCall('tenants/' + curTenant?.tenantId + '/users')
				.then(res => {
					setUsers(res.data)
				})
			user.apiCall('tenants/' + curTenant?.tenantId)
				.then(res => {
				if (res.data[0] !== undefined) {
					setMetadata(res.data[0])
					setChangeObject(res.data[0]);

					let startDate = new Date(res.data[0]?.leaseStart ?? null);
					if(!res.data[0]?.leaseStart) {
						startDate.setDate(new Date().getDate()-30);
					}
					setLeaseStartDate(new Date(res.data[0]?.leaseStart) || new Date())
					let now = new Date();
					now.setDate(now.getDate() - 1)

					if (startDate < now) {
						fetchRecords(startDate);
					}
				}
				})
				
		}
	}, [curTenant]);

	useEffect(() => {
		let newFiltered = records;
		if(tranDtFlt.length === 2) {
			newFiltered = newFiltered.filter((val) =>
				val.transactionsDate >= tranDtFlt[0] && val.transactionsDate <= tranDtFlt[1]
			);
		}
		if(lastModDtFlt.length === 2) {
			newFiltered = newFiltered.filter((val) =>
				val.lastModified >= lastModDtFlt[0] && val.lastModified <= lastModDtFlt[1]
			);
		}
		if(modByEmail !== "" && modByEmail.length > 0) {
			newFiltered = newFiltered.filter((val) =>
				val.lastModifiedByEmail?.toUpperCase().includes(modByEmail.toUpperCase())
			);
		}

		setFilteredData([...newFiltered]);
	}, [tranDtFlt, lastModDtFlt, modByEmail])

	const fetchRecords = (startDt) => {
		user.apiCall('tenants/' + curTenant?.tenantId + '/concessionRecords?filter=all')
			.then(res => {
				let idx = 0;
				let week = 0;
				let dayArray = [];
				let sumMon = 0;
				let sumTran = 0;
				let prevDay = new Date();
				prevDay = new Date(format(prevDay, "yyyy-MM-dd") + " 23:59:59");

				do {
					dayArray.push({
						week: week,
						lastModified: null,
						lastModifiedByEmail: null,
						tenantId: curTenant.tenantId,
						transactionsDate: format(prevDay, formatStr),
						monetaryAmount: 0,
						numTransactions: 0
					});

					if(res.data.length > idx) {
						const trDt = new Date(res.data[idx].transactionsDate);
						if (format(trDt, "yyyy-MM-dd") === format(prevDay, "yyyy-MM-dd")) {
							dayArray[dayArray.length - 1] = {
								...res.data[idx],
								week: week
							}

							sumMon = sumMon + res.data[idx].monetaryAmount;
							sumTran = sumTran + res.data[idx].numTransactions;
							idx++;
						}
					}

					if (prevDay.getDay() === 1) {
						week++;
					}
					prevDay = addDays(prevDay, -1);
				} while (prevDay >= startDt)

				setRecords(dayArray);
				setFilteredData(dayArray);
				setSumMonetrary(sumMon);
				setSumTransaction(sumTran);
			})
	}

	const buttonBodyTemplate = (rowData) => (
		<Button tooltip="Delete User"
			style={{ display: "table", margin: "0 auto" }}
			tooltipOptions={{ position: 'left' }}
			onClick={() => {
				setUserToDeleteId(rowData.userId); setUserToDelete(rowData.email); setDisplayDeleteDialog(true)
			}}
			label={<i className="pi pi-times"></i>}
			className="p-button-rounded p-button-primary"
		/>
	)

	const buttonHeaderTemplate = () => (
		<Button tooltip="Add User"
			style={{ display: "table", margin: "0 auto" }}
			tooltipOptions={{ position: 'left' }}
			onClick={() => setDisplayDialog(true)}
			label={<i className="pi pi-plus "></i>}
			className="p-button-rounded p-button-primary"
		/>
	)

	const renderFooter = () => (
		<div>
			<Button label="Save"
				icon="pi pi-check"
				onClick={() => {
					addUser();
					setDisplayDialog(false);
				}}
			/>
		</div>
	)

	const formatDate = (value) => {
		return value.toLocaleDateString('en-US', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
		});
	}

	const deleteUser = () => {
		user.apiCall('tenants/' + curTenant?.tenantId + '/users/' + userToDeleteId, {},'DELETE')
			.then((res) => {
				if (res.status === 200) {
					global.successToast(removeUserMsg);
					user.apiCall('tenants/' + curTenant?.tenantId + '/users')
						.then(res => {
							setUsers(res.data)
						})
				}
				else {
					global.errorToast(removeUserFail);
				}
			})
	}

	const addUser = () => {
		if (userToAdd.includes("@flyPittsburgh.com")) {
			global.errorToast(recordAdminFail);
		}
		else {
			const userBody = {
				Email: userToAdd,
				LastLoggedIn: new Date()
			}
			user.apiCall('users', userBody, 'POST')
				.then((res) => {
					if (res.status === 200) {
						const tenantBody = {
							UserId: res.data.userId,
							TenantId: curTenant.tenantId,
							AddedDate: new Date(),
							AddedBy: user.username,
							IsManager: isManager,
							UserEmail: userToAdd,
							TenantName: metadata?.name
						}
						user.apiCall('tenants/' + curTenant.tenantId + '/users', tenantBody,'POST')
							.then((utRes) => {
								if (utRes.status === 200) {
									global.successToast(addUserMsg);
									user.apiCall('tenants/' + curTenant.tenantId + '/users')
										.then(res => {
											setUsers(res.data)
											setUserToAdd('')
											setIsManager(false)
										})
								}
								else {
									global.errorToast(addUserFail);
								}
							})
					}
					else {
						global.errorToast(addUserFail);
					}
				})
		}
	}

	const inputNumericEditor = (props, field) => (
		<InputNumber value={props.rowData[field]}
			onChange={(e) => props.editorCallback(e.value)}
			mode="decimal"
			locale="en-US"
			maxFractionDigits={2}
		/>
	)

	const addOrUpdateRecords = (props) => {
		if (props.newData.tenantId && user.username && props.newData.transactionsDate) {
			let saveValue = [{
				ConcessionRecordId: props.newData.concessionRecordId,
				TenantId: props.newData.tenantId,
				MonetaryAmount: props.newData.monetaryAmount,
				NumTransactions: props.newData.numTransactions,
				LastModified: new Date(),
				LastModifiedByEmail: user.username,
				TransactionsDate: props.newData.transactionsDate
			}];

			user.apiCall('concessionRecords', saveValue,'POST')
				.then((res) => {
					if (res.status === 200) {
						global.successToast(recordUpdateMsg);
						fetchRecords(leaseStartDate);
					}
					else {
						global.errorToast(recordUpdateFail)
					}
				})
		}

	}

	const changeName = (e) => {
		let tempTenant = changeObject;
		changeObject.name = e.target.value
		setChangeObject(changeObject => ({ ...changeObject, ...tempTenant }));
	}

	const changeStart = (e) => {
		let tempTenant = changeObject;
		changeObject.leaseStart = e.target.value
		setChangeObject(changeObject => ({ ...changeObject, ...tempTenant }));
	}

	const changeEnd = (e) => {
		let tempTenant = changeObject;
		changeObject.leaseEnd = e.target.value
		setChangeObject(changeObject => ({ ...changeObject, ...tempTenant }));
	}

	const changeExpiration = (e) => {
		let tempTenant = changeObject;
		changeObject.leaseExpiration = e.target.value
		setChangeObject(changeObject => ({ ...changeObject, ...tempTenant }));
	}

	const changeUnit = (e) => {
		let tempTenant = changeObject;
		changeObject.unitId = e.value
		setChangeObject(changeObject => ({ ...changeObject, ...tempTenant }));
	}

	const changeTenantType = (e) => {
		let tempTenant = changeObject;
		changeObject.tenantTypeId = e.value
		setChangeObject(changeObject => ({ ...changeObject, ...tempTenant }));
	}

	const cancelEdit = () => {
		setChangeObject(changeObject => ({ ...changeObject, ...metadata }));
		setEditState(false)
		if (props.tenant === "new") {
			props.goBack();
		}
	}

	const saveTenant = (activateTenant) => {
		if (curTenant.tenantId) {
			console.log("Activate tenant",activateTenant)
			if(activateTenant === true){
				changeObject.isActive = true;
			}
			user.apiCall('tenants/' + changeObject.tenantId, changeObject,'PUT')
				.then((res) => {
					user.apiCall('tenants/' + curTenant?.tenantId)
						.then((res) => {
							setMetadata(res.data[0]);
							setEditState(false);
						})
					if(res.status == 200){
						global.successToast("Changes Saved Successfully")
					}
				})
				.catch((e) => global.errorToast(e.response.data))
		}
		else {
			user.apiCall("tenants", changeObject, 'POST')
				.then((res) => {
					user.apiCall('tenants/' + res.data)
						.then(res => {
							setMetadata(res.data[0]);
							setCurTenant(res.data[0]);
							setEditState(false);
						})
					if(res.status == 200){
						global.successToast("Tenant added")
					}
				})
				.catch((e) => global.errorToast(e.response.data))
		}

	}

	const footerTemplate = (data) => {
		let monOut = (Math.round(calculateTotalSales(data.week) * 100) / 100).toFixed(2);
		return (
			<React.Fragment>
				<td></td>
				<td colSpan="1" >
					<b>Total Sales: ${monOut}</b>
				</td>
				<td colSpan="1">
					<b>Total Transactions: {calculateTotalTransactions(data.week)}</b>
				</td>
			</React.Fragment>
		);
	}

	const calculateTotalSales = (week) => {
		let total = 0;

		if (records) {
			for (let record of records) {
				if (record.week === week) {
					total += record.monetaryAmount;
				}
			}
		}

		return total;
	}

	const calculateTotalTransactions = (week) => {
		let total = 0;

		if (records) {
			for (let record of records) {
				if (record.week === week) {
					total += record.numTransactions;
				}
			}
		}

		return total;
	}

	const dateFilterTemplate = (getter, setter) => {
		return (
			<Calendar value={getter}
				onChange={(e) => setter(e.value ?? [])}
				selectionMode="range"
			/>
		);
	}

	const deleteTenant = () => {
		user.apiCall('tenants/' + curTenant?.tenantId, {},'DELETE')
			.then((res) => {
				if (res.status === 200) {
					global.successToast(tenantRemoveMsg);
					props.goBack();
				}
				else {
					global.errorToast(removeUserFail);
				}
			})
	}

	const checkBodyTemplate = (rowData, props) => {
		if (rowData.registered === true) {
			const iconStyle = { color: "green", display: "table", margin: "0 auto", padding:"0px", fontSize: "30px" };
			const iconClassname = "pi pi-check valid" + rowData.userId;
			return (
				<>
					<Tooltip target={".valid" + rowData.userId}
						content="Registered"
						position="left" />
					<i style={iconStyle} className={iconClassname}></i>
				</>
			)
		}
		else {
			const iconStyle = { color: "red", display: "table", margin: "0 auto", fontSize: "30px" };
			const iconClass = "pi pi-times error" + rowData.userId;
			return (
				<>
					<Tooltip target={".error" + rowData.userId}
						content="Not Registered"
						position="left"
					/>
					<i style={iconStyle} className={iconClass}></i>
				</ >
			)
		}
	}

	const monetaryTemplate = (rowData) => {
		return (Math.round(rowData.monetaryAmount * 100) / 100).toFixed(2);
	}

	
	const handleChange = ()=>{
		
	}
	return (
		<div >
			<Dialog header="Invite User"
				visible={displayDialog}
				style={{ width: '20vw', textAlign: 'center' }}
				footer={renderFooter}
				onHide={() => setDisplayDialog(false)}
				className="user-invite"
			>
				<div>
					Email:&nbsp;
					<InputText value={userToAdd} onChange={(e) => setUserToAdd(e.target.value)} />
				</div>
				<div>
					Manager:&nbsp;
					<Checkbox onChange={e => setIsManager(e.checked)} checked={isManager} />
				</div>
			</Dialog>
			<ConfirmDialog visible={displayDeleteDialog}
				onHide={() => setDisplayDeleteDialog(false)}
				message={"Do you want to remove " + userToDelete + " from this tenant?"}
				header="Delete Confirmation"
				icon="pi pi pi-info-circle"
				accept={() => deleteUser()}
				reject={null}
			/>
			<ConfirmDialog visible={displayTenantDeleteDialog}
				onHide={() => setDisplayTenantDeleteDialog(false)}
				message={"Do you want to deactivate this tenant?"}
				header="Deactivate Confirmation"
				icon="pi pi pi-info-circle"
				accept={() => deleteTenant()}
				reject={null}
			/>
			<TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} >
				<TabPanel header="Config" leftIcon='pi pi-fw pi-cog'>
					<Config 
						editState={editState}
						setEditState={(edit) =>setEditState(edit)}
						metadata={metadata}
						units={units}
						changeUnit={changeUnit}
						formatDate={formatDate}
						setDisplayTenantDeleteDialog={(tenant)=>setDisplayTenantDeleteDialog(tenant)}
						changeObject={changeObject}
						changeName={changeName}
						tenantTypes={tenantTypes}
						changeTenantType={changeTenantType}
						changeEnd={changeEnd}
						changeStart={changeStart}
						changeExpiration={changeExpiration}
						saveTenant={saveTenant}
						cancelEdit={cancelEdit}
						
					/>
				</TabPanel>
				<TabPanel header="Users" leftIcon='pi pi-fw pi-user'>
					<UsersTab 
							users={users}
							checkBodyTemplate={checkBodyTemplate}
							buttonBodyTemplate={buttonBodyTemplate}
							buttonHeaderTemplate={buttonHeaderTemplate}
							dateOnlyTemplate={dateOnlyTemplate}
							changeObject={changeObject}
					/>
				</TabPanel>
				<TabPanel header="Concessions" leftIcon='pi pi-fw pi-chart-bar'>
					<ConcessionsData 
							dt={dt}
							filteredData={filteredData}
							footerTemplate={footerTemplate}
							addOrUpdateRecords={addOrUpdateRecords}
							exportColumns={exportColumns}
							dateOnlyTemplate={dateOnlyTemplate}
							dateFilterTemplate={dateFilterTemplate}
							monetaryTemplate={monetaryTemplate}
							inputNumericEditor={inputNumericEditor}
							sumMonetrary={sumMonetrary.toFixed(2)}
							sumTransaction={sumTransaction}
							modByEmail={modByEmail}
							setModByEmail={setModByEmail}
							tranDtFlt={tranDtFlt}
							setTranDtFlt={setTranDtFlt}
							lastModDtFlt={lastModDtFlt}
							setLastModDtFlt={setLastModDtFlt}
							changeObject={changeObject}
					/>
				</TabPanel>
				<TabPanel header="Hours" leftIcon='pi pi-fw pi pi-clock'>
					
					<Hours  curTenant={curTenant} user={user} changeObject={changeObject} />
				</TabPanel>
			</TabView>

		</div>
	);
}
