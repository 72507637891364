import { Button } from 'primereact/button';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';

export const Config = ({
    units,
    changeUnit,
    changeObject,
    changeName,
    tenantTypes,
    changeTenantType,
    changeEnd,
    changeStart,
    changeExpiration,
    saveTenant,
    cancelEdit,
    setDisplayTenantDeleteDialog
  }) => {
    const getFieldLabel = (value, label) => {
        return value ? label : ' ';
    };

    return (
        <div id="Config">          
            <div className='grid'>
                <div className='col'>
                    <div className="p-field">
                        <span className="p-float-label">
                            <InputText id="name" value={changeObject?.name} onChange={changeName} className='p-float-label-input-text' />
                            <label htmlFor="name">Name</label>
                        </span>
                    </div>
                </div>
                <div className='col'>
                    <div className="p-field">
                        <span className="p-float-label">
                            <Dropdown id="unit" value={changeObject?.unitId}
                                options={units}
                                onChange={changeUnit}
                                optionValue="unitId"
                                optionLabel="unitName"
                            />
                            <label htmlFor="unit">Unit</label>
                        </span>
                    </div>
                </div>
                <div className='col'>
                    <div className="p-field">
                        <span className="p-float-label">
                            <Dropdown id="tenantType" value={changeObject?.tenantTypeId}
                                options={tenantTypes}
                                onChange={changeTenantType}
                                optionValue="tenantTypeId"
                                optionLabel="name"
                            />
                            <label htmlFor="tenantType">Tenant Type</label>
                        </span>
                    </div>
                </div>
            </div>
            <div className='grid'>
                <div className='col'>
                        <div className="p-field">
                            <span className="p-float-label">
                                <Calendar id="leaseStart" value={new Date(changeObject?.leaseStart)} onChange={changeStart} />
                                <label htmlFor="leaseStart">Lease Start</label>
                            </span>
                        </div>
                    </div>
                <div className='col'>
                    <div className="p-field">
                        <span className="p-float-label">
                            <Calendar id="leaseEnd" value={new Date(changeObject?.leaseEnd)} onChange={changeEnd} />
                            <label htmlFor="leaseEnd">Lease End</label>
                        </span>
                    </div>
                </div>
                <div className='col'>
                    <div className="p-field">
                        <span className="p-float-label">
                            <Calendar id="leaseExpiration" value={new Date(changeObject?.leaseExpiration)} onChange={changeExpiration} />
                            <label htmlFor="leaseExpiration">Lease Expiration</label>
                        </span>
                    </div>
                </div>
            </div>
            <p><i>All fields required</i></p>
            <Button tooltip="Save Tenant"
                disabled={!changeObject?.name || !changeObject?.unitId || !changeObject?.tenantTypeId || !changeObject?.leaseStart || !changeObject?.leaseEnd || !changeObject?.leaseExpiration}
                onClick={saveTenant}
                tooltipOptions={{ position: 'left' }}
                label={<i className="pi pi-check "></i>}
                className="p-button-rounded p-button-primary"
            />
            &nbsp;
            <Button tooltip="Cancel"
                onClick={cancelEdit}
                tooltipOptions={{ position: 'left' }}
                label={<i className="pi pi-times "></i>}
                className="p-button-rounded p-button-primary"
            />
            &nbsp;
            <Button
                label={changeObject.isActive ?<i className="pi pi-ban"></i>:<i className="pi pi-play"></i>}
                tooltip={changeObject.isActive ? "Deactivate Tenant" : ""}
                onClick={() => changeObject.isActive?setDisplayTenantDeleteDialog(true): saveTenant(true)}
                tooltipOptions={{ position: 'left' }}
                className={changeObject.isActive ? 'p-button-rounded p-button-danger' : 'p-button-rounded p-button-success'}
            />
        </div> 
    )
};